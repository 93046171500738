import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const HealthItCompliance = () => {
  return (
    <Layout>
      <Seo
        title="Compliance & Security | Bridge"
        description="Bridge patient portal is regulated by important security certificates to provide all its customers with greater peace of mind in their services."
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1>Compliance & Security</h1>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h2>
                Never resting, Bridge is always advancing its efforts in keeping
                our client&apos;s PHI and data safe.
              </h2>
              <p>
                Compliance and security take the highest priority at Bridge and
                is consistently the fastest-growing department in Bridge&apos;s
                business. We believe that compliance and security policies and
                procedures must be integrated into every aspect of the business.
                This is especially pronounced in how Bridge trains its staff,
                implements its technology, services its customers, develops and
                hosts its software, performs quality assurance, and audits its
                systems. Trust Bridge to take patient engagement to the next
                level just as many of healthcare&apos;s most respected names
                have already done.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage src="../images/Soc-2.png" alt="Soc 2" />
            </Col>
            <Col lg={8}>
              <h2>SOC 2</h2>
              <p>
                We&apos;re proud to have received SOC 2 certification, which is
                an auditing procedure that ensures third-party vendors such SaaS
                and cloud computing providers securely manage your data and the
                privacy of your patients. Developed by the American Institute of
                CPAs, SOC 2 defines criteria for managing customer data based on
                five “trust service principles” — security, availability,
                processing integrity, confidentiality and privacy. For
                security-conscious organizations, SOC 2 compliance should be a
                minimal requirement when considering a SaaS provider.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage
                src="../images/onc-logo-big.png"
                alt="ONC 2015 Edition Certification"
              />
            </Col>
            <Col lg={8}>
              <h2>ONC 2015 Edition Certification</h2>
              <p>
                Bridge Patient Portal v3 is certified by SLI, an Office of the
                National Coordinator-Authorized Certification Body (ONC-ACB) in
                accordance with the certification criteria adopted by the
                Secretary of Health and Human Services (HHS). The portal helps
                provider groups meet requirements for MIPS, MACRA, and Stage 3
                Meaningful Use programs. Bridge also complies with the USCDI
                data set and the CURES Act.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage src="../images/hipaa_badge.png" alt="HIPAA" />
            </Col>
            <Col lg={8}>
              <h2>HIPAA</h2>
              <p>
                Bridge is compliant with the Health Insurance Portability and
                Accountability Act of 1996 (HIPAA), which defines privacy and
                security guidelines and standards for the healthcare industry.
                Besides HIPAA being part of Bridge&apos;s culture, Bridge
                business practices are audited annually by a 3rd party (
                <a
                  href="https://compliancy-group.com/hipaa-compliance-verification/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Compliancy Group
                </a>
                ), and its systems are audited even more frequently.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage src="../images/pci-logo.jpg" alt="PCI Compliance" />
            </Col>
            <Col lg={8}>
              <h2>PCI Compliance</h2>
              <p>
                Bridge applies PCI cybersecurity guidelines in their development
                and infrastructure. As a best practice, Bridge never hosts,
                transmits, or stores credit card information. Instead, Bridge
                uses payment gateway partners for all credit card processing.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage
                src="../images/lets-encrypt-logo.png"
                alt="TLS Encryption and Certificates"
              />
            </Col>
            <Col lg={8}>
              <h2>TLS Encryption and Certificates</h2>
              <p>
                Bridge applies strong encryption in transit by applying and
                updating their configurations regularly according to NIST
                recommendations on TLS versions and on the usage of strong
                cipher-suites in EVERYTHING we do.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage
                src="../images/cloudflare-logo.jpg"
                alt="Next Generation Firewalls"
              />
            </Col>
            <Col lg={8}>
              <h2>Next Generation Firewalls</h2>
              <p>
                Bridge uses various next-generation firewalls to perform deep
                packet inspection (DPI) on all communications. Bridge Patient
                Portal also uses web application firewalls (WAFs) with custom
                rulesets and other advanced Cloudflare services.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={4} className="text-center">
              <StaticImage src="../images/amazon-logo.jpg" alt="Hosting" />
              <StaticImage src="../images/google-logo.jpg" alt="Hosting" />
            </Col>
            <Col lg={8}>
              <h2>Hosting</h2>
              <p>
                Bridge exclusively uses Amazon Web Services and Google Cloud
                Platform for application hosting and exclusively uses the
                HIPAA-compliant cloud services of both cloud hosting providers.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>
                <strong>Trademark Notice:</strong>
              </p>
              <p>
                All product names, logos, and brands are property of their
                respective owners. All company, product, and service names used
                in this website are for identification purposes only. Use of
                these names, logos, and brands does not imply endorsement.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default HealthItCompliance;
